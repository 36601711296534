import * as React from "react";
import Layout from "../components/Layout";

import "@fontsource/dm-sans";
import "./payment-success.sass";

// markup

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkout: null,
    };
  }
  componentDidMount() {
    window.parent.postMessage("close_iFrame");
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (typeof this.props.location.state  !== "undefined" && typeof params.get('type') !== "string") {
      console.log("TIMMY")
      this.setState({
        checkout: this.props.location.state,
      });
    } else {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let type = params.get('type');
      let date = decodeURIComponent(params.get('date'));
      let phone = decodeURIComponent(params.get("phone"));
      let email = params.get("email");
      let prestationName = decodeURIComponent(params.get("prestationName"));
      let aboStart = typeof params.get("aboStart") !== "undefined" ? params.get("aboStart") : "";
      let associates = "";
      let capital = "";
      let canton = "";
      if(type === "company"){
        associates = params.get("associates");
        capital = params.get("capital");
        canton = params.get("canton");
      }

      this.setState({
        //decodeURIComponent(   
        checkout: {
          type: type,
          date: date,
          phone: phone,
          email: email,
          prestationName: prestationName,
          aboStart: aboStart,
          meta: {
            associates: associates,
            capital: capital,
            canton: canton
          }

        }
      })
    }
  }

  returnFullDateFr = () => {
    let selectedDate = new Date(this.state.checkout.date);
    let str1 = selectedDate.toLocaleDateString("fr-FR", { weekday: "long" });
    str1 = str1.toLowerCase();

    let str2 = selectedDate.getDate();
    let str3 = selectedDate.toLocaleString("fr-FR", { month: "long" });
    let str4 = selectedDate.getFullYear();
    let str5 =
      " à " +
      selectedDate.getHours() +
      "h" +
      selectedDate.getMinutes() +
      (selectedDate.getMinutes() === 0 ? "0" : "");
    return (
      str1 +
      " " +
      str2 +
      " " +
      str3 +
      " " +
      str4 +
      str5
    )
  };



  returnAboName = () => {
    let prest = this.state.checkout.prestationName;
    let abo = prest.split(" ")[1];
    return "Votre abonnement juridique " + abo;
  };

  generateDateStringFrenchAbo = () => {
    let splitStr = this.state.checkout.aboStart.split(".")
    let year = parseInt(splitStr[2]);
    let month = parseInt(splitStr[1]) - 1 ;
    let day = parseInt(splitStr[0]);
    let d = new Date(); 
    if(d.getDate() == day && d.getMonth() == month){
      return "aujourd'hui"
    }
    let startDate = new Date(year, month, day);
    let str1 = startDate.toLocaleDateString("fr-FR", { weekday: "long" });
    str1 = str1.toLowerCase();
    let str2 = startDate.getDate();
    let str3 = startDate.toLocaleString("fr-FR", { month: "long" });
    let str4 = startDate.getFullYear();

    let str = "le " + str1 + " " + str2 + " " + str3 + " " + str4;
    return str;
  }

  returnCapital = () => {
    let cap = this.state.checkout.meta.capital.toString();
    let str1 = cap.slice(0, cap.length - 3);
    let str2 = cap.slice(cap.length - 3, cap.length);
    return str1 + "'" + str2 + ".-";
  };

  returnCanton = () => {
    let cantonLetter = this.state.checkout.meta.canton.substring(0,3);
    switch (cantonLetter) {
      case "Gen":
        return "de Genève";
      case "Vau":
        return "de Vaud";
      case "Fri":
        return "de Fribourg";
      case "Neu":
        return "de Neuchâtel";
      case "Val":
        return "du Valais";
      default:
        return "";
    }
  };

  returnAssociates = () => {
    switch (this.state.checkout.meta.associates) {
      case "1":
        return "un";
      case "2":
        return "deux";
      case "3":
        return "trois";
      case "4":
        return "quatre";
      default:
        return "";
    }
  };

  render() {
    return (
      <Layout style={{ zIndex: 9999 }}>
        <div className="datatrans-hider" />
        <div className="background-color-div">
          <div className="header-row">
            <div id="header-container ">
              <h3>CONFIRMATION</h3>
              {this.state.checkout !== null &&
              this.state.checkout.type === "call" ? (
                <p className="text-success">
                  Votre rendez-vous téléphonique le {this.returnFullDateFr()} a
                  bien été enregistré. Nous vous remercions de votre commande.{" "}
                  <br />
                  <br />
                  Un e-mail de confirmation vous a été envoyé à l’adresse{" "}
                  <b>{this.state.checkout.email}</b> avec les détails et
                  informations dont vous pourriez avoir besoin. <br />
                  <br />
                  Si aucun e-mail ne vous est parvenu, merci de nous en informer
                  par e-mail à info@legalify.ch ou de nous appeler au +41 22 731
                  40 31.
                  <br />
                  <br />
                  Bonne journée et à bientôt. <br />
                  <br />
                  <b>L’équipe Legalify</b>
                </p>
              ) : (
                ""
              )}
              {this.state.checkout !== null &&
              this.state.checkout.type === "meet" ? (
                <p className="text-success">
                  Votre entretien dans nos locaux le {this.returnFullDateFr()} a
                  bien été enregistré. Nous vous remercions de votre commande.{" "}
                  <br />
                  <br />
                  Un e-mail de confirmation vous a été envoyé à l’adresse{" "}
                  <b>{this.state.checkout.email}</b> avec les détails et
                  informations dont vous pourriez avoir besoin. <br />
                  <br />
                  Si aucun e-mail ne vous est parvenu, merci de nous en informer
                  par e-mail à info@legalify.ch ou de nous appeler au +41 22 731
                  40 31.
                  <br />
                  <br />
                  Bonne journée et à bientôt. <br />
                  <br />
                  <b>L’équipe Legalify</b>
                </p>
              ) : (
                ""
              )}
              {this.state.checkout !== null &&
              this.state.checkout.type === "abo" ? (
                <p className="text-success">
                  {this.returnAboName()} a bien été enregistré. Il débute dès {this.generateDateStringFrenchAbo()}.<br/>
                  <br/> Nous vous remercions de votre commande. <br />
                  <br />
                  Un e-mail de confirmation vous a été envoyé à l’adresse{" "}
                  <b>{this.state.checkout.email}</b> avec les détails et
                  informations dont vous pourriez avoir besoin. <br />
                  <br />
                  Si aucun e-mail ne vous est parvenu, merci de nous en informer
                  par e-mail à info@legalify.ch ou de nous appeler au +41 22 731
                  40 31.
                  <br />
                  <br />
                  Bonne journée et à bientôt. <br />
                  <br />
                  <b>L’équipe Legalify</b>
                </p>
              ) : (
                ""
              )}
              {this.state.checkout !== null &&
              this.state.checkout.type === "contract" ? (
                <p className="text-success">
                  Votre commande{" ("}{this.state.checkout.prestationName}{")"} a bien été enregistrée.
                  Nous vous en remercions. <br />
                  <br />
                  Un e-mail de confirmation vous a été envoyé à l’adresse{" "}
                  <b>{this.state.checkout.email}</b>, avec les documents de votre commande.
                  <br />
                  <br />
                  Si aucun e-mail ne vous est parvenu ou si des documents sont manquants, merci de nous en informer
                  par e-mail à info@legalify.ch ou de nous appeler au +41 22 731
                  40 31.
                  <br />
                  <br />
                  Bonne journée et à bientôt. <br />
                  <br />
                  <b>L’équipe Legalify</b>
                </p>
              ) : (
                ""
              )}
              {this.state.checkout !== null &&
              this.state.checkout.type === "company" &&
              this.state.checkout.prestationName ===
                "Constitution d'une société anonyme" ? (
                <p className="text-success">
                  Votre commande pour la création d’une société anonyme dans le
                  canton {this.returnCanton()} a bien été enregistrée. Nous vous
                  en remercions. <br />
                  <br />
                  Le capital-actions prévu est de {this.returnCapital()} francs
                  et le nombre d’actionnaires de {this.returnAssociates()}.
                  <br />
                  <br />
                  Un e-mail de confirmation vous a été envoyé à l’adresse{" "}
                  <b>{this.state.checkout.email}</b>, avec la marche à suivre
                  pour la création de votre société.
                  <br />
                  <br />
                  Si aucun e-mail ne vous est parvenu, merci de nous en informer
                  par e-mail à info@legalify.ch ou de nous appeler au +41 22 731
                  40 31.
                  <br />
                  <br />
                  Bonne journée et à bientôt. <br />
                  <br />
                  <b>L’équipe Legalify</b>
                </p>
              ) : (
                ""
              )}

              {this.state.checkout !== null &&
              this.state.checkout.type === "company" &&
              this.state.checkout.prestationName ===
                "Constitution d'une société à responsabilité limitée" ? (
                <p className="text-success">
                  Votre commande pour la création d’une société à responsabilité
                  limitée dans le canton {this.returnCanton()} a bien été
                  enregistrée. Nous vous en remercions. <br />
                  <br />
                  Le capital social prévu est de {this.returnCapital()} francs
                  et le nombre d’associés de {this.returnAssociates()}.<br />
                  <br />
                  Un e-mail de confirmation vous a été envoyé à l’adresse{" "}
                  <b>{this.state.checkout.email}</b>, avec la marche à suivre
                  pour la création de votre société.
                  <br />
                  <br />
                  Si aucun e-mail ne vous est parvenu, merci de nous en informer
                  par e-mail à info@legalify.ch ou de nous appeler au +41 22 731
                  40 31.
                  <br />
                  <br />
                  Bonne journée et à bientôt. <br />
                  <br />
                  <b>L’équipe Legalify</b>
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="checkout-row"></div>
        </div>
      </Layout>
    );
  }
}

export default Success;
